import React from "react";
import { Link } from "react-router-dom";

const GalleryItem = (props) => {
    
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div key={props.event.id} className="col-md-4 col-sm-12 col-12 events">
      <div className="grid">
        <Link
          to={{
            pathname: `${props.event.id}`,
            query: `${props.event.title}`,
          }}
          onClick={scrollTop}
        >
          <img
            className="feature-img gallery_img"
            src={`${props.event.img_url}`}
            width={`${props.event.width}`}
            height={`${props.event.height}`}
            alt=" "
          />
        </Link>
        {/* <Link to={{pathname: `/${this.props.testvalue}`, query: {backUrl}}} /> */}

        <p className="grid-heading">
          <Link
            to={{
              pathname: `${props.event.id}`,
              query: `${props.event.title}`,
            }}
            onClick={scrollTop}
          >
            {props.event.title}
          </Link>
        </p>

        {/* <p className="grid-description text-limit">
          {props.event.description}{" "}
        </p> */}
        {/* <p className="learn-more-link">
        <Link
          to={{
            pathname: `${item.id}`,
            query: `${item.title}`,
          }}
        >
          Learn More
          <i className="fa fa-angle-right hero-arrow"></i>
        </Link>
      </p> */}
      </div>
    </div>
  );
};

export default GalleryItem;
