import React from "react";
import { Link } from "react-router-dom";

const PageTitle = (props) => {
  return (
    <section
      className="page-title bg-overlay-black-60 parallax"
      style={{
        backgroundImage: "url(" + "./assets/images/about.jpg" + ")",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title-name">
              <h1>{props.title}</h1>
              <p>{props.description}
              </p>
            </div>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">
                  {" "}
                  <i className="fa fa-home"></i> Home
                </Link>{" "}
                <i className="fa fa-angle-double-right"></i>
              </li>

              <li>
                <span>{props.currentPageName}</span>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
