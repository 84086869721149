import React from "react";
import { Link } from "react-router-dom";
import BaseUrl from "../../api/BaseUrl";
import PageTitle from "../PageTitle";
import PortfolioItem from "./PortfolioItem";

const currentdate = new Date().toLocaleDateString();
export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      title: "Project Details",
      description:
        "When you pay attention to detail, the big picture will take care of itself.",
      currentPageName: "Portfolio",
    };

    this.getRecord();
  }


  getRecord() {
    const apiUrl = `${BaseUrl}/api/getportfolios`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.setState({ data }));
    //.then((data) => console.log('data', data.response));

    console.log("ss", this.state.data);
  }

  render() {
    return (
      <div>
        <PageTitle
          title={this.state.title}
          description={this.state.description}
          currentPageName={this.state.currentPageName}
        />

        <section className="contact white-bg page-section-ptb">
          <div className="container">
            {this.state.data.map((item) => (
              <PortfolioItem key={item.id} product={item} />
            ))}
          </div>
        </section>
      </div>
    );
  }
}
