import React, { useState, useEffect } from "react";
import BaseUrl from "../api/BaseUrl";
// import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardSubTitle, MDBCardText, MDBCardLink } from 'mdb-react-ui-kit';
import "./../App.css";
const currentdate = new Date().toLocaleDateString();

export default class JobOpening extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recorddata: [],
      jobdata: [],
      cardActive: "",
    };
    this.getRecord();
  }

  getRecord() {
    const apiUrl = `${BaseUrl}/api/getjobopenings`;
    fetch(apiUrl)
      .then((response1) => response1.json())
      .then((data1) => this.setState({ recorddata: data1 }));
    //.then((data) => console.log('data', data.response));

    console.log("ml", this.state.recorddata);
  }

  getRecordById(id) {
    if (id != 0) {
      const apiUrl = `${BaseUrl}/api/getjobopeningbyid/${id}`;
      fetch(apiUrl)
        .then((response2) => response2.json())
        .then((data2) => this.setState({ jobdata: data2 }));
      this.setState({ cardActive: id });
      // console.log("mm", this.state.data);
    }
  }

  render() {
    return (
      <section className="JobOpening white-bg page-section-pt mb-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="section-title">
                <h2 className="title-effect">Let's Grow Together</h2>
                <h3 className="title-effect">We are having Openings for:</h3>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-12 col-md-12 col-sm-12"> */}
            {this.state.recorddata != "" ? (
              <div>
                {this.state.recorddata.map((item) => (
                  // <JobCard id={item.id} title={item.title} location={item.location}/>

                  <div
                    className="col-lg-3 col-md-3 col-sm-3 card-shadow "
                    style={{
                      paddingTop: "10px",
                      width: "27.5rem",
                      height: "6rem",
                      marginLeft: "13.5px",
                      marginBottom: "10px",
                      backgroundColor:
                        item.id === this.state.cardActive ? "#8BC34A" : "white",
                    }}
                    onClick={() => this.getRecordById(item.id)}
                    key={item.id}
                  >
                    <div className="card-body">
                      <div key={item.id}>
                        <div className="">
                          <strong>{item.title}</strong>
                        </div>
                        <div className="">{item.location}</div>
                        {/* <button className="btn" style={{backgroundColor:'#8BC34A',color:'white'}} onClick={() => getRecordById(item.id)}>View details</button> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="col-lg-12 col-md-12 col-sm-12 card-shadow "
                style={{
                  paddingTop: "20px",
                  height: "6rem",
                  marginLeft: "13.5px",
                  marginBottom: "10px",
                }}
              >
                <div className="card-body">
                  <div>No current opening</div>
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
          {/* <div className="row mt-sm-5 ">
            <div className="col-lg-12 col-md-12 col-sm-12 "> */}
          <br></br>
          {this.state.jobdata.map((job) => (
            <div>
              {/* {job.job_description ? ( */}
              <div className="row mt-md-5">
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                  <div className="feature-text box-shadow text-left ">
                    <div className="feature-info">
                      <h4 className="pb-10">
                        <b> {job.title}</b>
                      </h4>
                      <p>
                        <b>Description :</b> {job.job_description}
                      </p>
                      <p>
                        <b>Experience :</b> {job.min_experience} -{" "}
                        {job.max_experience} years.
                      </p>
                      <p>
                        <b>Skills :</b> {job.skills}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* ) : (<div></div>)} */}
            </div>
          ))}
          {/* </div>
          </div> */}
        </div>
      </section>
    );
  }
}
