import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./assets/css/plugins/bootstrap.min.css";
import "./assets/css/plugins/mega_menu.css";
import "./assets/css/plugins/animate.css";

import "./assets/css/plugins/font-awesome.min.css";
import "./assets/css/plugins/magnific-popup.css";
import "./assets/css/plugins/owl.carousel.min.css";
import "./assets/css/plugins/themify-icons.css";
import "./assets/revolution/css/settings.css";

import "./assets/css/typography.css";
// import './assets/css/shortcodes/shortcodes.css';

import "./assets/css/shortcodes/accordion.css";
import "./assets/css/shortcodes/bootstrap-typography.css";
import "./assets/css/shortcodes/button.css";
import "./assets/css/shortcodes/clients.css";
import "./assets/css/shortcodes/contact-form.css";
import "./assets/css/shortcodes/countdown.css";
import "./assets/css/shortcodes/list-style.css";
import "./assets/css/shortcodes/owl-carousel.css";
import "./assets/css/shortcodes/page-title.css";

import "./assets/css/style.css";
import "./assets/css/responsive.css";

import Header from "./components/Header";
import Home from "./components/home/Home";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Career from "./components/Career";
import Portfolio from "./components/portfolio/Portfolio";
import Gallery from "./components/gallery/Gallery";
import GalleryDetail from "./components/gallery/GalleryDetail";
// import "bootstrap/dist/css/bootstrap.min.css";
import BaseUrl from "./api/BaseUrl";

function App() {
  return (
    //staging browse route
    // <BrowserRouter basename="/iloma_staging/iloma_website_frontend">
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/portfolio" component={Portfolio}></Route>
        <Route exact path="/career" component={Career}></Route>
        <Route exact path="/contactus" component={Contact}></Route>
        <Route exact path="/gallery" component={Gallery}></Route>
        <Route exact path="/:id" component={GalleryDetail}></Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
