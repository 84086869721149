import React from 'react';
import { Link } from 'react-router-dom';


import Service from './Service';
import Banner from './Banner';
import Features from './Features';
import aboutImage1 from "../assets/images/about/01.jpg";


function About() {


	return  <div>

		<section className="page-section-ptb">
  <div className="container">
     <div className="row">
           <div className="col-lg-6 col-md-6 col-md-push-6">
           <img className="img-responsive" src={aboutImage1}/>

      </div>
      <div className="col-lg-6 col-md-6 col-md-pull-6 sm-mt-30">
        <div className="section-title">
            <h6>Who we are and what we do</h6>
            <h2 className="title-effect">Get to know us better.</h2>
            <p>iLoma Technology is a mobile development company started by highly passionate, adaptive and trained mobile developers. We love to code beautiful and powerful mobile applications with great user experience.</p>
          </div>
          <p>  </p>
          <div className="row mt-30">
          <div className="col-md-6">
            <ul className="list list-unstyled list-hand">
              <li>Creative</li>
              <li>Hardworking</li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="list list-unstyled list-hand">
              <li>Supportive</li>
              <li>Passionate</li>
            </ul>
          </div>
      </div>
      </div>
     </div>
       <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-4">
         <div className="feature-text left-icon mt-50">
          <div className="feature-icon">
              <span className="ti-home theme-color" aria-hidden="true"></span>
              </div>
            <div className="feature-info">
              <h5 className="text-back">Our company</h5>
              <p>iLoma Technology is a team of young and experienced Mobile engineers. We work together to convert <b>ideas</b> into <b>wonderful mobile applications</b>.</p>
            </div>
         </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4">
        <div className="feature-text left-icon mt-50">
        <div className="feature-icon">
              <span className="ti-server theme-color" aria-hidden="true"></span>
              </div>
            <div className="feature-info">
              <h5 className="text-back">Our Mission</h5>
              <p>We want to establish healthy and strong relationship with our clients and to become the <b>trusted technology partners</b> with them.</p>
            </div>
         </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4">
        <div className="feature-text left-icon mt-50">
        <div className="feature-icon">
             <span className="ti-heart theme-color" aria-hidden="true"></span>
              </div>
            <div className="feature-info">
              <h5 className="text-back">We Love</h5>
              <p><b>Listening</b> to your ideas and convert them into reality and to <b>code, execute, repeat. </b></p>
            </div>
         </div>
        </div>
     </div>
  </div>
</section>


	</div>


}

export default About 