import React from "react";
import BaseUrl from "../../api/BaseUrl";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GalleryDetail from "./GalleryDetail";
import PageTitle from "../PageTitle";
import GalleryItem from "./GalleryItem";



export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      title: "Gallery",
      description: "Have a look at moments we have shared over the years...",
      currentPageName: "Gallery",
    };
    this.getRecord();
  }

  getRecord() {
    const apiUrl = `${BaseUrl}/api/getgallery`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.setState({ data }));
    //.then((data) => console.log('data', data.response));

    console.log("abc", this.state.data);
  }

  render() {
    return (
      <div>
        <PageTitle
          title={this.state.title}
          description={this.state.description}
          currentPageName={this.state.currentPageName}
        />

        <section className="contact white-bg page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-md-offset-2">
                <div className="section-title text-center">
                  <h2>Let’s see our activity !</h2>
                  <p>
                    It would be great to organising and enjoying the activity
                    and events. it would gives mind fresh to us
                  </p>
                </div>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "10px" }}>
              <div className="touch-in white-bg">
                <div className="container">
                  <div className="row event-main">
                    {this.state.data.map((item) => (
                      <GalleryItem event={item}/>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
