//live hompage url
//  "homepage": "http://www.ilomatechnology.com",

//aws live url
// let BaseUrl = "http://www.ilomatechnology.com/iloma_react/iloma_website_admin";


//hostingerlive hompage url
//  "homepage": "https://ilomatechnology.com",

//hostinger new live url
let BaseUrl = "https://ilomatechnology.com/iloma_website_admin";

//staging homepage url
  // "homepage": "/iloma_staging/iloma_website_frontend/",
  
//staging url
// let BaseUrl = "https://myvivahmatrimony.in/iloma_staging/iloma_website_admin/";

//local url
// let BaseUrl = "http://localhost/iloma/iloma_website_admin";

export default BaseUrl;
