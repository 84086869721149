import React, { Component } from "react";
import BaseUrl from "../api/BaseUrl";

class JobApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studFirstName: "",
      studLastName: "",
      emailId: "",
      studMessage: "",
      studCv: [],
      info: "",
      formErrors: {},
    };

    this.initialState = this.state;
    this.reset = this.reset.bind(this);
  }

  reset() {
    // Changing state
    this.setState({ studFirstName: "" });
    this.setState({ studLastName: "" });
    this.setState({ emailId: "" });
    this.setState({ studMessage: "" });
    this.setState({ studCv: "" });
    this.setState({ info: "" });
    this.setState({ formErrors: "" });
  }

  handleFormValidation() {
    const { studFirstName, studLastName, emailId, studMessage, studCv } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    //first name
    if (!studFirstName) {
      formIsValid = false;
      formErrors["studFirstNameErr"] = "Required";
    } else if (!/^[a-zA-Z ]*$/.test(studFirstName)) {
      formIsValid = true;
      formErrors["studFirstNameErr"] = "Enter only alphabetic characters";
    }

    //last name
    if (!studLastName) {
      formIsValid = true;
      formErrors["studLastNameErr"] = "Required";
    } else if (!/^[a-zA-Z ]*$/.test(studLastName)) {
      formIsValid = false;
      formErrors["studLastNameErr"] = "Enter only alphabetic characters";
    }
    //message
    if (!studMessage) {
      formIsValid = false;
      formErrors["studMessageErr"] = "Required";
    }

    //Email
    if (!emailId) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Required";
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Enter valid Email";
    }

    //cv
    let file;
    if (this.state.studCv.length == 0) {
      file = "";
    } else {
      file = this.state.studCv.name.split(".").pop();
    }

    if (this.state.studCv.length == 0) {
      formIsValid = false;
      formErrors["studCvErr"] = "Required";
    } else if (file != "pdf" && file != "doc" && file != "docx") {
      formIsValid = false;
      formErrors["studCvErr"] = "Invalid file extension";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileChange = (e) => {
    console.log(e.target.files[0]);
    // const { name, value } = e.target.files[0];
    this.setState({ studCv: e.target.files[0] });
    console.log("filename", this.state.studCv);
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.handleFormValidation()) {
      const formData = new FormData();

      formData.append("cv", this.state.studCv);
      formData.append("first_name", this.state.studFirstName);
      formData.append("last_name", this.state.studLastName);
      formData.append("email", this.state.emailId);
      formData.append("message", this.state.studMessage);
      formData.append("type", 1);
      let result = await fetch(`${BaseUrl}/api/addjobapplication`, {
        method: "post",
        // mode: "no-cors",
        body: formData,
      });
      const apiResponse = await result.json();
      console.log("check",apiResponse);
      this.setState({ info: apiResponse.status });

      console.warn(apiResponse.status);
      // alert('You have been successfully registered.')
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      e.target.reset();
      this.setState(this.initialState);
    }
  };

  render() {
    const {
      studFirstNameErr,
      studLastNameErr,
      emailIdErr,
      studMessageErr,
      studCvErr,
    } = this.state.formErrors;

    return (
      <section className="JobOpening white-bg page-section-pb">
        <div className="container ">
          <div className="row centerDiv">
            <div className=" col-lg-8 col-md-8 col-sm-8 ">
              <div className="feature-text box-shadow text-left">
                {/* <h4 className="">Job Application</h4><br></br> */}
                <div className="formDiv">
                  <h4 style={{ textAlign: "center" }}>Job Application</h4>
                  <div>
                    <form onSubmit={this.handleSubmit} id="career_form">
                      <div>
                        <label htmlFor="studFirstName">First Name </label>
                        <label style={{ color: "red" }}> *</label>
                        <input
                          type="text"
                          name="studFirstName"
                          value={this.state.studFirstName}
                          onChange={this.handleChange}
                          className={
                            studFirstNameErr
                              ? " showError form-control"
                              : "form-control"
                          }
                        />
                        {studFirstNameErr && (
                          <div style={{ color: "red", paddingBottom: 10 }}>
                            {studFirstNameErr}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="studLastName">Last Name</label>
                        <label style={{ color: "red" }}> *</label>
                        <input
                          type="text"
                          name="studLastName"
                          value={this.state.studLastName}
                          onChange={this.handleChange}
                          className={
                            studLastNameErr
                              ? " showError form-control"
                              : "form-control"
                          }
                        />
                        {studLastNameErr && (
                          <div style={{ color: "red", paddingBottom: 10 }}>
                            {studLastNameErr}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="emailId">Email</label>
                        <label style={{ color: "red" }}> *</label>
                        <input
                          type="text"
                          name="emailId"
                          value={this.state.emailId}
                          onChange={this.handleChange}
                          className={
                            emailIdErr
                              ? " showError form-control"
                              : "form-control"
                          }
                        />
                        {emailIdErr && (
                          <div style={{ color: "red", paddingBottom: 10 }}>
                            {emailIdErr}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="studMessage">
                          Message (Max 500 characters)
                        </label>
                        <label style={{ color: "red" }}> *</label>
                        <textarea
                          name="studMessage"
                          maxLength="500"
                          value={this.state.studMessage}
                          onChange={this.handleChange}
                          className={
                            studMessageErr
                              ? " showError form-control maxHeight"
                              : "form-control maxHeight"
                          }
                        />
                        {studMessageErr && (
                          <div style={{ color: "red", paddingBottom: 10 }}>
                            {studMessageErr}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="studCv">
                          Upload Resume (only .pdf,.doc,.docx files accepted)
                        </label>
                        <label style={{ color: "red" }}> *</label>
                        <input
                          type="file"
                          name="studCv"
                          //   value={this.state.studCv.name}
                          accept=".pdf, .doc, .docx"
                          onChange={this.handleFileChange}
                          className={
                            studCvErr
                              ? " showError form-control"
                              : "form-control"
                          }
                        />
                        {studCvErr && (
                          <div style={{ color: "red", paddingBottom: 10 }}>
                            {studCvErr}
                          </div>
                        )}
                      </div>
                      <button
                        type="Submit"
                        className="btn"
                        style={{
                          backgroundColor: "#8BC34A",
                          color: "white",
                          marginTop: "10px",
                        }}
                      >
                        Apply
                      </button>
                      <button
                        type="reset"
                        className="btn"
                        style={{
                          backgroundColor: "#8BC34A",
                          color: "white",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                        onClick={this.reset}
                      >
                        Reset
                      </button>
                      {/* <input type="submit" value="Submit" />     */}
                    </form>

                    <div
                      className={this.state.info == true ? "show" : "hide"}
                      style={{
                        color: "#8BC34A",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Application Submitted !
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default JobApplication;
