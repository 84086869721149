import React from 'react';
import { Link } from 'react-router-dom';
function Footer() {


	return  <div>


			<section className="contact-box contact-box-top theme-bg">
        <div className="container">
            <div className="row pt-20 pb-40">
                <div className="col-lg-4 col-sm-4 xs-mb-30">
                    <div className="contact-box">
                        <div className="contact-icon">
                            <i className="ti-map text-white"></i>
                        </div>
                        <div className="contact-info">
                            <h5 className="text-white">Address</h5>
                            <span className="text-white">Plot No 54, Bhagiratha Park(near sutgirni), Post-Wanadogri, Tah-Hingna, Nagpur-441110 (Maharashtra)</span>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-4 xs-mb-30">
                    <div className="contact-box">
                        <div className="contact-icon">
                            <i className="ti-headphone text-white"></i>
                        </div>
                        <div className="contact-info">
                            <h5 className="text-white"> Call</h5>
                            <span className="text-white">+918208545489</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                    <div className="contact-box">
                        <div className="contact-icon">
                            <i className="ti-email text-white"></i>
                        </div>
                        <div className="contact-info">
                            <h5 className="text-white"> Email </h5>
                            <span className="text-white">info@ilomatechnology.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


<footer className="footer  black-bg">
 <div className="container">


      <div className="footer-widget mt-0">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 xs-mb-20">
           <p className="mt-15"> &copy;Copyright {new Date().getFullYear()}<span id="copyright"> <script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script></span> <Link to="/"> iLoma Technology Pvt. Ltd.</Link> All Rights Reserved </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 text-right xs-text-left">
            <div className="footer-widget-social">
             <ul>
              <li><a href="https://www.facebook.com/ilomatechnology" target="_blank"><i className="fa fa-facebook"></i></a></li>
              <li><a href="https://twitter.com/IlomaTechnology"target="-blank"><i className="fa fa-twitter"></i></a></li>
              <li><a href="https://www.linkedin.com/company/ilomatechnology2017/mycompany/" target="_blank"><i className="fa fa-linkedin"></i> </a></li>


              <li><a href="https://www.youtube.com/channel/UC_VXsYYustqfEIAOxV8nfxA" target="_blank"><i className="fa fa-youtube-play"></i></a></li>


             </ul>
           </div>
          </div>
        </div>
      </div>
  </div>
</footer>

	</div>


}

export default Footer