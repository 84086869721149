import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BaseUrl from "../api/BaseUrl";
import IlomaLogo from "../assets/images/logo.png";


const Header = () => {
  const [isGallery, setIsGallery] = useState(false);
  // const [res, setRes] = useState([]);

  const location = useLocation();

  async function getGalleryCount() {
    const apiUrl = `${BaseUrl}/api/getgallerycount`;
    console.log(apiUrl);

    let result = await fetch(apiUrl);

    const apiResponse = await result.json();
    if(apiResponse.status){
      setIsGallery(true);
    }
  }

  useEffect(() => {
    getGalleryCount();
  }, []);

  return (
    <header id="header" className="header fancy without-topbar text-dark">
      <div className="menu">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <nav id="menu" className="mega-menu">
                <section className="menu-list-items">
                  <ul className="menu-logo">
                    <li>
                      <Link to="/">
                        <img id="logo_img" src={IlomaLogo} alt="logo" />
                      </Link>
                    </li>
                  </ul>
                  <div className="menu-bar">
                    <ul className="menu-links">
                      <li className={location.pathname === "/" ? "active" : ""}>
                        <Link className="header_menus" to="/">
                          Home
                        </Link>{" "}
                      </li>
                      <li
                        className={
                          location.pathname === "/portfolio" ? "active" : ""
                        }
                      >
                        <Link className="header_menus" to="/portfolio">
                          Portfolio{" "}
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/career" ? "active" : ""
                        }
                      >
                        <Link className="header_menus" to="/career">
                          Career{" "}
                        </Link>
                      </li>
                      {isGallery && <li
                        className={
                          location.pathname === "/gallery" ? "active" : ""
                        }
                      >
                        <Link className="header_menus" to="/gallery">
                          Gallery{" "}
                        </Link>
                      </li>}
                      <li
                        className={
                          location.pathname === "/contactus" ? "active" : ""
                        }
                      >
                        <Link className="header_menus" to="/contactus">
                          Contact US{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </section>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

// export default class Header extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       menuName: "",
//     };
//     this._handleClick(props);
//   }

//   _handleClick(menuItem) {
//     this.setState({ menuName: menuItem });

//   }

//   render() {

//     // if (this.state.menuName === "home") {
//     //   const active = "active";
//     // } else {
//     //   const active = "";
//     // }

//     // if (
//     //   this.state.menuName === "portfolio" ||
//     //   this.state.menuName === "career" ||
//     //   this.state.menuName === "gallery" ||
//     //   this.state.menuName === "contact"
//     // ) {
//     //   const deactive = "active";
//     // }

//     return (
//       <header id="header" className="header fancy without-topbar text-dark">
//         <div className="menu">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-12 col-md-12">
//                 <nav id="menu" className="mega-menu">
//                   <section className="menu-list-items">
//                     <ul className="menu-logo">
//                       <li>
//                         <Link to="/">
//                           <img
//                             id="logo_img"
//                             src="./assets/images/logo.png"
//                             alt="logo"
//                           />
//                         </Link>
//                       </li>
//                     </ul>
//                     <div className="menu-bar">
//                       <ul className="menu-links">
//                         <li
//                           className={
//                             this.state.menuName === "home" ? "active" : ""
//                           }
//                         >
//                           <Link
//                             className="header_menus"
//                             to="/"
//                             onClick={this._handleClick.bind(this, "home")}
//                           >
//                             Home
//                           </Link>{" "}
//                         </li>
//                         <li
//                           className={
//                             this.state.menuName === "portfolio" ? "active" : ""
//                           }
//                         >
//                           <Link
//                             className="header_menus"
//                             to="/portfolio"
//                             onClick={this._handleClick.bind(this, "portfolio")}
//                           >
//                             Portfolio{" "}
//                           </Link>
//                         </li>
//                         <li
//                           className={
//                             this.state.menuName === "career" ? "active" : ""
//                           }
//                         >
//                           <Link
//                             className="header_menus"
//                             to="/career"
//                             onClick={this._handleClick.bind(this, "career")}
//                           >
//                             Career{" "}
//                           </Link>
//                         </li>
//                         <li
//                           className={
//                             this.state.menuName === "gallery" ? "active" : ""
//                           }
//                         >
//                           <Link
//                             className="header_menus"
//                             to="/gallery"
//                             onClick={this._handleClick.bind(this, "gallery")}
//                           >
//                             Gallery{" "}
//                           </Link>
//                         </li>
//                         <li
//                           className={
//                             this.state.menuName === "contact" ? "active" : ""
//                           }
//                         >
//                           <Link
//                             className="header_menus"
//                             to="/contactus"
//                             onClick={this._handleClick.bind(this, "contact")}
//                           >
//                             Contact US{" "}
//                           </Link>
//                         </li>
//                       </ul>
//                     </div>
//                   </section>
//                 </nav>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//     );
//   }
// }
