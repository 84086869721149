import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/revolution/assets/banner1.jpg";

function Banner() {
  return (
    <div>
      <section className="rev-slider">
        <img src={BannerImage} alt="" className="img-responsive" />
      </section>
    </div>
  );
}

export default Banner;
