import React from 'react';
import { Link } from 'react-router-dom';
import JobOpening from "./JobOpening";
import JobApplication from "./JobApplication";
import JobApp_2 from "./JobApp_2"
function Career() {

  const inlinestyle = {
    backgroundImage: "url(" + "./assets/images/about.jpg" + ")",
  }

  return <div>


    <section className="page-title bg-overlay-black-60 parallax" style={inlinestyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title-name">
              <h1>Career</h1>
              <p>Let’s make something awesome together</p>
            </div>
            <ul className="page-breadcrumb">
              <li><Link to="/"> <i className="fa fa-home"></i> Home</Link> <i className="fa fa-angle-double-right"></i></li>

              <li><span>Career</span> </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
<JobOpening />
<br></br>
{/* <JobApplication /> */}
<JobApp_2/>

  </div>


}

export default Career