import React from "react";
import DefaultUserImage from "../../assets/images/user_dummy_pic.png";

const TestimonialItem = (props) => {
  return (
    <React.Fragment>
      <figure className="snip1192" key={props.key}>
        <blockquote>{props.description} </blockquote>
        <div className="author">
          <img
            src={props.fileExist === "" ? DefaultUserImage : props.imgUrl}
            alt="sq-sample1"
          />
          <h5>
            {props.name}
            <span>
              {" "}
              {props.position} at {props.companyName}
            </span>
          </h5>
        </div>
      </figure>
    </React.Fragment>
  );
};

export default TestimonialItem;
