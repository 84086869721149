import React from 'react';
import { Link } from 'react-router-dom';


import Service from './Service';
import Banner from './Banner';
import FeatureImage1 from "../assets/images/about/07.jpg"


function Features() {


  return  <div>

  <section className="awesome-features gray-bg page-section-ptb pos-r">
   <div className="side-background">
        <div className="col-md-5 img-side img-left visible-md visible-lg">
            <div className="row page-section-pt mt-30">
              <img src={FeatureImage1} alt=""/>
            </div>
        </div>
    </div>
   <div className="container">
     <div className="row">
       <div className="col-lg-7 col-md-7 col-sm-12 col-md-offset-5">
       <div className="section-title">
          <h6>Why  iLoma Technology for your next mobile app? </h6>
          <h2 className="title-effect">Our awesome core features</h2>
          <p></p>
        </div>
         <div className="row">
           <div className="col-lg-6 col-md-6 col-sm-6">
             <div className="feature-text text-left mb-30">
             <div className="feature-icon">
              <span className="ti-ink-pen theme-color"></span>
              </div>
              <div className="feature-info">
                  <h5>Attractive design</h5>
                   <p>Our creative team loves to make new designs and create <b>great user experience</b> for mobile applications.</p>
               </div>
           </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
             <div className="feature-text text-left mb-30">
             <div className="feature-icon">
              <span className="ti-headphone theme-color"></span>
              </div>
              <div className="feature-info">
                <h5>Fast Customer support</h5>
                 <p>We are willing to <b>support</b> our clients in all possible ways and as quick as possible.</p>
               </div>
           </div>
          </div>
         </div>
         <div className="row">
           <div className="col-lg-6 col-md-6 col-sm-6">
             <div className="feature-text text-left mt-30">
             <div className="feature-icon">
              <span className="ti-heart  theme-color"></span>
              </div>
              <div className="feature-info">
              <h5>Understanding and Care</h5>
               <p>Your <b>ideas are equally valuable</b> for us and we take our best effort to understand it deeply so we don't miss even untold features which are essential for the product.</p>
             </div>
           </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
             <div className="feature-text text-left mt-30">
             <div className="feature-icon">
              <span className="ti-face-smile theme-color"></span>
              </div>
              <div className="feature-info">
                  <h5>Passionate</h5>
                   <p>Our passion drive us to <b>create something amazing</b> and increases our interest in work.</p>
              </div>
           </div>
          </div>
         </div>
       </div>
     </div>
   </div>
 </section>


 

  </div>


}

export default Features 