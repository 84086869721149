import React from "react";
import { Link } from "react-router-dom";
import BaseUrl from "../api/BaseUrl";
import "./../App.css";
import PageTitle from "./PageTitle";

const currentdate = new Date().toLocaleDateString();
export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      title: "Contact Us",
      description: "We are happy to connect with you!",
      currentPageName: "Contact Us",
    };
    this.getRecord();
  }

  getRecord() {
    const apiUrl = `${BaseUrl}/api/getcontactus`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.setState({ data }));
    //.then((data) => console.log('data', data.response));

    console.log("ss", this.state.data);
  }

  render() {
    return (
      <div>
        <PageTitle
          title={this.state.title}
          description={this.state.description}
          currentPageName={this.state.currentPageName}
        />

        <section className="contact white-bg page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-md-offset-2">
                <div className="section-title text-center">
                  <h2>Let’s make something awesome together</h2>
                  <p>
                    It would be great to hear from you! Please connect with us
                    via mobile, email or we would be glad to meet you in person
                    at our office
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="touch-in white-bg">
                {this.state.data.map((item) => (
                  <div key={item.id} className="col-lg-4 col-md-4 col-sm-4">
                    <a target="_blank" href={item.link}>
                      <div className="contact-box text-center">
                        <i className={`theme-color ${item.icon}`}></i>
                        <h5 className="uppercase mt-20">{item.title}</h5>
                        <p className="mt-20">{item.value}</p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
