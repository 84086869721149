import React from "react";
import ProjectDetails from "./ProjectDetails";
import ProjectImage from "./ProjectImage";

const PortfolioItem = (props) => {
  return (
    <div key={props.product.id} className="row marg-20">
      <div className="col-lg-3 col-md-10 col-sm-2">
        <div className="who-we-are-left port-singal">
          <div
            className="owl-carousel bottom-left-dots owl-loaded owl-drag"
            data-nav-dots="ture"
            data-items="1"
            data-md-items="1"
            data-sm-items="1"
            data-xs-items="1"
            data-xx-items="1"
          >
            <ProjectImage imageUrl={props.product.img_url} />

            <div className="owl-nav disabled">
              <div className="owl-prev">
                <i className="fa fa-angle-left fa-2x"></i>
              </div>
              <div className="owl-next">
                <i className="fa fa-angle-right fa-2x"></i>
              </div>
            </div>
            <div className="owl-dots disabled">
              <div className="owl-dot active">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectDetails
        appName={props.product.app_name}
        detail={props.product.detail}
        client={props.product.client}
        platform={props.product.platform}
        technology={props.product.technology}
        playstore_url={props.product.plastore_url}
        appstore_url={props.product.appstore_url}
      />
    </div>
  );
};

export default PortfolioItem;
