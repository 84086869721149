import React from "react";
import { Link } from "react-router-dom";
import BaseUrl from "../api/BaseUrl";

const currentdate = new Date().toLocaleDateString();
export default class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.getRecord();
  }

  getRecord() {
    const apiUrl = `${BaseUrl}/api/getservices`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.setState({ data }));
    
  }

  render() {
    return (
      <section className="service white-bg page-section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="section-title">
                <h6>We're Good At</h6>
                <h2 className="title-effect">Our Services</h2>
                <p>
                  We always try to provide best services. Get more insight to
                  it.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.data.map((item) => (
              <div key={item.id} className="col-lg-6 col-md-6 col-sm-6">
                <div className="feature-text box-shadow text-center mb-30 service-card">
                  <div className="feature-icon">
                    <span className={`theme-color ${item.icon}`}></span>
                  </div>
                  <div className="feature-info">
                    <h4 className="pb-10">{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
