import React from "react";

const GalleryDetailItem = (props) => {
  return (
    <div className="gallery_card">
      <h4 className="title_head">{props.eventDetail.title}</h4>
      <img
        className="d-block gallery_detail_image"
        src={props.eventDetail.img_url}
        alt={props.eventDetail.title}
        width={`${props.eventDetail.width}`}
        height={`${props.eventDetail.height}`}
      />
    </div>
  );
};

export default GalleryDetailItem;
