import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Carousel } from "react-bootstrap";
import BaseUrl from "../../api/BaseUrl";
import PageTitle from "../PageTitle";
import GalleryDetailItem from "./GalleryDetailItem";

export default class GalleryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      galleryData: [],
      title: "Company Events",
      description: "Here’s how we have fun at work!",
      currentPageName: "Gallery",
    };
    this.getRecord(props);

    console.log("ss", this.props);
  }

  getRecord(props) {
    const apiUrl = `${BaseUrl}/api/getgalleryImages/${props.match.params.id}`;
    const galApiUrl = `${BaseUrl}/api/getGalleryByID/${props.match.params.id}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.setState({ data }));

    fetch(galApiUrl)
      .then((response) => response.json())
      .then((galleryData) => this.setState({ galleryData }));

    // console.log("ss galleryData", this.state.galleryData);
  }

  render() {
    return (
      <>
        <PageTitle
          title={this.state.title}
          description={this.state.description}
          currentPageName={this.state.currentPageName}
        />

        <section className="contact white-bg page-section-ptb">
          <div className="container">
            <div>
              <div className="section-title text-center">
                <h2 className="main_title_heading text-20">
                  {" "}
                  {this.state.galleryData.title}
                </h2>
                <p className="gallery-grid-description">{this.state.galleryData.description} </p>
              </div>
            </div>

            {/* <Carousel
                fade={true}
                pause={false}
              > */}
            <div className="">
              {this.state.data.map((item) => {
                return (
                  // <Carousel.Item key={item.id} interval={50000}>
                  <GalleryDetailItem eventDetail={item} />
                  // </Carousel.Item>
                );
              })}
            </div>
            {/* </Carousel> */}
          </div>
        </section>
      </>
    );
  }
}
