import React from "react";

const ProjectImage = (props) => {
  return (
    <div className="owl-stage-outer">
      <div className="owl-stage">
        <div className="owl-item cloned">
          <div className="item-portfolio">
            <img src={`${props.imageUrl}`} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectImage;
