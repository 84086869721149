import React, { useState,useRef} from 'react'
import BaseUrl from "../api/BaseUrl";
import Alert from 'react-bootstrap/Alert';
// import { ToastContainer, toast } from 'react-toastify'; import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator'
function JobApplication() {
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [cv, setCV] = useState("");
    const [data, setData] = useState("");
    const [info, setInfo] = useState("");
    const ref = useRef();
    const [emailError, setEmailError] = useState('');
    const [first_nameError, setFirstNameError] = useState('');
    const [last_nameError, setLastNameError] = useState('');
    const [messageError, setMessageError] = useState('');
    // const [cvError, setCVError] = useState('');

    async function addJobApplication() {
        resetError();
        setEmailError((email == '') ? 'Required' : '');
        // alert(emailError)
        if(emailError != 'Required'){
            setEmailError((validator.isEmail(email)) ? '' : 'Enter valid Email!');
            }
        setFirstNameError((first_name == '') ? 'Required' : '');
        setLastNameError((last_name == '') ? 'Required' : '');
        setMessageError((message == '') ? 'Required' : '');
        // setCVError((cv.length == 0) ? 'Required' : '');
        

        if(emailError != '' || first_nameError!= '' || last_nameError != '' || messageError != ''){
            return;
        }else{
        const formData = new FormData();
        formData.append("cv", cv);
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("email", email);
        formData.append("message", message);
        formData.append("type", 1);
        if(emailError ==''){
        let result = await fetch(`${BaseUrl}/api/addjobapplication`, {
            method: 'post',
            // mode: "no-cors",
            body: formData
        });
    
        const apiResponse = await result.json();
        
        console.warn(apiResponse.status);
        setData(apiResponse.status);
    }
}
        // setData(apiResponse.status);
        console.warn(emailError);
        // setInfo(data == true ? "Application Submitted !" : "All fields are Required !");
        if(data == true && emailError ==''){
            setInfo("Application Submitted !");
            reset();
        }
        return;
    }

    function reset() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        ref.current.value = "";
        setEmailError("");
        setFirstNameError("");
        setLastNameError("");
        setMessageError("");
        // setDisplay("hidden");
        // setCVError("");
        // setCV("");
        // setInfo("")
    }

    function resetError() {
        setEmailError("");
        setFirstNameError("");
        setLastNameError("");
        setMessageError("");
    }
    return (
        <section className="JobOpening white-bg page-section-pb" >
            <div className="container ">
                <div className="row centerDiv" >
                    <div className=" col-lg-8 col-md-8 col-sm-8 " >
                        <div className="feature-text box-shadow text-left" >
                            <h4 className="">Job Application</h4><br></br>
                            {/* <ToastContainer /> */}

                            <form>
                            <label>First Name </label><label style={{color:'red'}}> *</label>
                                <input type="text" className="form-control "
                                    onChange={(e) => setFirstName(e.target.value)} value={first_name} />
                                 <div style={{ color: 'red',marginTop:'5px' }}>
                                {/* {first_nameError} */}
                                </div>
                                <label>Last Name </label><label style={{color:'red'}}> *</label>
                                <input type="text" className="form-control "
                                    onChange={(e) => setLastName(e.target.value)} placeholder="" value={last_name} style={{marginTop:''}}/>
                                <div style={{ color: 'red',marginTop:'5px' }}>
                                {last_nameError}
                                </div>
                                <label>Email</label><label style={{color:'red'}}> *</label>
                                <input type="text" className="form-control "
                                    onChange={(e) => setEmail(e.target.value)} placeholder="" value={email} style={{marginTop:'' }}/>
                                <div style={{ color: 'red',marginTop:'5px' }}>
                                {emailError}
                                </div>
                                <label>Message (Max 500 characters)</label><label style={{color:'red'}}> *</label>
                                <textarea className="form-control maxHeight"
                                    onChange={(e) => setMessage(e.target.value)} placeholder="" value={message} style={{marginTop:''}} maxLength='500'/>
                                <div style={{ color: 'red',marginTop:'5px' }}>
                                {messageError}
                                </div>
                                <label>Upload Resume (only .pdf,.doc,.docx files accepted)</label>
                                <input type="file" className="form-control " accept=".pdf,.doc,.docx"
                                    onChange={(e) => setCV(e.target.files[0])} placeholder="" ref={ref} style={{marginTop:''}}/>
                                {/* <div style={{ color: 'red',marginTop:'5px' }}>
                                {cvError}
                                </div> */}
                                <button type="reset" className="btn" style={{ backgroundColor: '#8BC34A', color: 'white',marginTop:'10px'}} onClick={addJobApplication}>Apply</button>
                                <button type="reset" className="btn" style={{ backgroundColor: '#8BC34A', color: 'white',marginLeft:'10px',marginTop:'10px'}} onClick={reset}>Reset</button>
                                <div className="success-msg" style={{ color: '#8BC34A',marginTop:'10px'}}>
                                {info}
                                </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobApplication