import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialItem from "./TestimonialItem";

const Testimonial = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div
      className="container text-center my-3"
      style={{ paddingBottom: "50px" }}
    >
      <h4 className="mb-5 font-weight-bold" style={{ fontSize: "32px" }}>
        What Our Client Say
      </h4>
      <div className="row mx-auto my-auto">
        <div
          id="myCarousel"
          className="carousel slide w-100"
          data-ride="carousel"
        >
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item py-5 ">
              <Carousel responsive={responsive}>
                {props.testimonial.map((item) => (
                  <TestimonialItem
                    key={item.id}
                    description={item.description}
                    fileExist={item.file_exist}
                    imgUrl={item.img_url}
                    name={item.name}
                    companyName={item.company_name}
                    position={item.position}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
