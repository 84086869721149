import React from "react";

import GooglePlayStoreImage from "../../assets/images/portfolio/google-play.png";
import AppStoreImage from "../../assets/images/portfolio/app-store-badge.png";

const ProjectDetails = (props) => {
  return (
    <div className="col-lg-8 col-md-5 col-sm-12 port-information">
      <div className="port-title sm-mt-40">
        <h3>{props.appName}</h3>
      </div>

      <div className="port-info mt-40 mb-20">
        <p>
          {props.detail}
          <br /> <br />
        </p>
      </div>

      <div className="port-meta clearfix">
        <ul className="list-unstyled">
          <li>
            <b>Client:</b>
            <span>{props.client}</span>
          </li>
          <li>
            <b>Platform: </b>
            <span>{props.platform}</span>
          </li>
          <li>
            <b>Technology: </b>
            <span>{props.technology}</span>
          </li>
        </ul>
      </div>
      <div className="share clearfix mt-10">
        <ul className="mylist">
          {props.playstore_url != "" && (
            <li>
              <a href={`${props.playstore_url}`} target="_blank">
                <img
                  src={GooglePlayStoreImage}
                  className="img-fluid"
                />
              </a>
            </li>
          )}
          {props.appstore_url != "" && (
            <li>
              <a href={`${props.appstore_url}`} target="_blank">
                <img
                  src={AppStoreImage}
                  className="img-fluid"
                />
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ProjectDetails;
