import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Service from "../Service";
import Banner from "../Banner";
import Features from "../Features";
import About from "../About";
import Testimonial from "../testimonial/Testimonial";
import BaseUrl from "../../api/BaseUrl";

function Home() {
  const [isTestimonial, setIsTestimonial] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);

  async function isTestimonialData() {
    const apiUrl = `${BaseUrl}/api/getalltestimonial`;

    let result = await fetch(apiUrl);

    const apiResponse = await result.json();

    if (apiResponse.status) {
      setIsTestimonial(apiResponse.status);
      setCount(apiResponse.count);
      setData(apiResponse.response);
    }
  }

  useEffect(() => {
    isTestimonialData();
  }, []);

  return (
    <div>
      <Banner />
      <About />
      <Features />
      <Service />
      {isTestimonial && <Testimonial count={count} testimonial={data} />}
    </div>
  );
}

export default Home;
